var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b03d0ed6ddedf216760bb78cb7efab84be05b10b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

const Sentry = require('@sentry/nextjs');
const { SENTRY_COMMON_CONFIG, SENTRY_CLIENT_CONFIG } = require('./lib/sentry');

Sentry.init({
  ...SENTRY_COMMON_CONFIG,
  ...SENTRY_CLIENT_CONFIG,
  integrations: [Sentry.replayIntegration(), Sentry.httpClientIntegration()],
});
